import React from "react"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import { Link } from "gatsby"

export default () => (
	<Layout style={{ color: `teal` }}>
		<Helmet title="Creditor Harassment and Collection Violations Lawyers | RWK Attorneys" defer={false} />

		<div className="container">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><Link to="/">Home</Link></li>
					<li class="breadcrumb-item"><Link to="/practice-areas">Practice Areas</Link></li>
					<li class="breadcrumb-item"><Link to="/practice-areas/bankruptcy">Bankruptcy</Link></li>
					<li class="breadcrumb-item active" aria-current="page">Creditor Harassment and Collection Violations</li>
				</ol>
			</nav>
			
			<h1>Creditor Harassment and Collection Violations</h1>

			<div class="post-content">
					<p>Are you tired of being harassed by creditors?&nbsp; Are they calling you every hour on the hour every day?</p>
<p>As the economy worsens, bad debt continues to grow.&nbsp; Creditors seem to be relentless in their quest to force you to pay the debt.&nbsp; If the original creditor cannot get you to pay the debt, often times debt collectors will purchase the debt from the original creditor in order to get you to pay the debt collection company.&nbsp; Both creditors and debt collection agencies have been putting more and more pressure on you to pay the debt.&nbsp; This pressure comes in the form of creditor harassment.</p>
<p>Some of the most common creditor harassment includes, but is not limited to:</p>
<p>•&nbsp;&nbsp; &nbsp;threats to hurt you or someone you know<br/>
•&nbsp;&nbsp; &nbsp;threats to damage your personal property<br/>
•&nbsp;&nbsp; &nbsp;use of obscene language<br/>
•&nbsp;&nbsp; &nbsp;threats to put you in jail<br/>
•&nbsp;&nbsp; &nbsp;threats to arrest you or issue a warrant for your arrest<br/>
•&nbsp;&nbsp; &nbsp;continued telephone calls to your workplace</p>
<p>In January 2010, a debt collection company paid $2.25 million to settle charges that it violated the Fair Debt Collection Practices Act, because it made false or deceptive threats of garnishment, arrest, and legal action; improper calls to consumers; frequent, harassing, threatening, and abusive calls; and unfair and unauthorized withdrawals from consumers’ bank accounts.</p>
<p>If you are being harassed by debt, contact us immediately for a free evaluation of your case!</p>
<p>Of course, creditor harassment does not end there!&nbsp; Under most circumstances, if you file a bankruptcy case and receive a discharge, the debt has been wiped out.&nbsp; However, debt collectors often buy and sell each other’s accounts, and these accounts usually include your debts that have been wiped out.&nbsp; In other cases, your original creditors continue to write you, telephone you, and send you billing statements on debt that has been wiped out.&nbsp; This, too, is prohibited by the bankruptcy laws.</p>
<p>We are currently handling several cases where mortgage companies have continued to dun our clients for their mortgage payments, even after a bankruptcy discharge.&nbsp; We are also pursuing cases where collection companies have threatened jail time for our client’s failure to pay a debt.</p>
<p>Stop the harassment now!&nbsp; Know your rights!&nbsp; <a title="Contact Us" href="https://rwkattorneys.com/contact-us/">Contact us</a> immediately for a free evaluation of your case.</p>
														</div>
		</div>
	</Layout>
)